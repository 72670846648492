import { AnalyticsModule, AnalyticsWidgetType, DrilldownFilter, WidgetSettings } from '@features/Analytics/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useWidgetDataPreview } from '@features/Analytics/useWidgetData';
import { Loader } from '@kit/ui/Loader';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import {
  Project,
  ProjectReport,
  ProjectReportCondition,
  ProjectReportFilter,
  ProjectRequestStatus,
  ProjectStatus
} from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { PropertyValue } from '@common/Properties/PropertyValue';
import { projectAdapterNew } from '@adapters/ProjectAdapters/ProjectAdapter';
import { formatDate, formatDuration } from '@utils';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { getPathnameByRecordType } from '@utils/routes';
import { AccountStatus, AccountType, RecordType } from '@types';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import { ProjectStageBadge } from '@components/ProjectStages';
import {
  TableContainer,
  Table,
  NextPageLoaderWrapper,
  PropertyWrapper,
  ValueWrapper,
  NoAccess,
  Blurred,
  ProjectChipContainer
} from './styled';
import { RecordTitleCell } from './RecordTitleCell';
import { useRecordsDynamicColumns } from './useRecordsDynamicColumns';
import { usePropertiesMap } from '../hooks';
import {
  ANALYTICS_MODULE_RECORD_TYPE_MAP,
  CLIENT_STATUS_OPTIONS,
  PROJECT_STATUS_OPTIONS,
  RECORD_TYPE_TITLE_MAP,
  REQUEST_STATUS_OPTIONS,
  STATUS_LIFECYCLE_WORKFLOWS
} from '../constants';

const TemplateTableCell = (props) => {
  const { children, ...rest } = props;

  return (
    <td {...rest}>
      <PropertyWrapper>{children}</PropertyWrapper>
    </td>
  );
};

const makeFakeRecord = (module: AnalyticsModule): DeepPartial<Project> => {
  return {
    title: 'No access',
    type: ANALYTICS_MODULE_RECORD_TYPE_MAP[module],
    accountStatus: AccountStatus.CUSTOMER,
    isActive: true,
    uid: 9999,
    accountType: AccountType.RESIDENTIAL,
    requestStatus: ProjectRequestStatus.New,
    status: ProjectStatus.Active
  };
};

const FAKE_RECORDS: Record<AnalyticsModule, DeepPartial<Project>> = {
  [AnalyticsModule.CLIENTS]: makeFakeRecord(AnalyticsModule.CLIENTS),
  [AnalyticsModule.PROJECTS]: makeFakeRecord(AnalyticsModule.PROJECTS),
  [AnalyticsModule.REQUESTS]: makeFakeRecord(AnalyticsModule.REQUESTS),
  [AnalyticsModule.WORK_ORDERS]: null
};

interface Props {
  settings: WidgetSettings;
  drilldownFilter?: DrilldownFilter<ProjectReportFilter, ProjectReportCondition>;
}

export const Records = ({ settings, drilldownFilter }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { propertiesMap, isPropertiesLoading } = usePropertiesMap(settings.module);
  const { data, isFetching, isFetchingNextPage, fetchNextPage } = useWidgetDataPreview<
    ProjectReport,
    ProjectReportFilter
  >(settings, drilldownFilter);

  const reports = useMemo(() => {
    return (
      data?.pages.flatMap((page) =>
        page.reports.map((report) => ({
          ...report,
          adaptedProject: report.project ? projectAdapterNew(report.project) : undefined
        }))
      ) ?? []
    );
  }, [data]);

  const totalCount = data?.pages[0]?.totalCount;

  const loadingRef = useRef<HTMLTableSectionElement>();

  const entry = useIntersectionObserver(loadingRef);

  const statusesMap = useMemo(() => {
    const options = (() => {
      switch (settings.module) {
        case AnalyticsModule.CLIENTS:
          return CLIENT_STATUS_OPTIONS;
        case AnalyticsModule.REQUESTS:
          return REQUEST_STATUS_OPTIONS;
        case AnalyticsModule.PROJECTS:
          return PROJECT_STATUS_OPTIONS;
        default:
          return [];
      }
    })();

    return options.reduce(
      (acc, option) => {
        acc[option.id] = option.name;

        return acc;
      },
      {} as Record<string, string>
    );
  }, [settings.module]);

  useEffect(() => {
    if (reports.length === 0 || isFetching) {
      return;
    }

    if (entry?.isIntersecting && reports.length < totalCount) {
      fetchNextPage();
    }
  }, [entry, reports, fetchNextPage, totalCount, isFetching]);

  const columns = useRecordsDynamicColumns(settings, propertiesMap);

  const handleRecordClick = useCallback(
    (report: ProjectReport) => () => {
      if (!report.project) {
        return;
      }

      window.open(
        `/${companyId}/${getPathnameByRecordType(report.project.type as RecordType)}/${report.project.id}`,
        '_blank'
      );
    },
    [companyId]
  );

  if (isPropertiesLoading) {
    return null;
  }

  const isTimeline = settings.widgetType === AnalyticsWidgetType.TIMELINE;
  const isStatusTimeline = isTimeline && STATUS_LIFECYCLE_WORKFLOWS.includes(settings.workflowId.toString());

  const statusOrStage = isStatusTimeline ? 'Status' : 'Stage';

  const totalColumns = (isTimeline ? 2 : 0) + columns.length + (settings.module === AnalyticsModule.CLIENTS ? 3 : 4);

  return (
    <TableContainer>
      <Table>
        <thead>
          <th>Record Name</th>
          {isTimeline && (
            <>
              <th>{statusOrStage}</th>
              <th>Time in {statusOrStage}</th>
            </>
          )}
          <th>Site address</th>
          {settings.module !== AnalyticsModule.CLIENTS && <th>Parent record</th>}
          <th>Created at</th>
          {columns.map((property) => (
            <th key={`id` in property ? property.id : property.name}>{property.name}</th>
          ))}
        </thead>
        <tbody>
          {reports.map((report, index) => {
            if (!report.project) {
              return (
                <tr key={index} className="blur">
                  <td>
                    <Blurred>
                      <RecordTitleCell record={FAKE_RECORDS[settings.module]} />
                    </Blurred>
                  </td>
                  <td colSpan={totalColumns}>
                    <NoAccess>
                      You don&apos;t have an access to this{' '}
                      {RECORD_TYPE_TITLE_MAP[ANALYTICS_MODULE_RECORD_TYPE_MAP[settings.module]]}.
                    </NoAccess>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={index} onClick={handleRecordClick(report)}>
                <td>
                  <RecordTitleCell record={report.project} />
                </td>
                {isTimeline && !isStatusTimeline && (
                  <>
                    <td>{report.timelineStage ? <ProjectStageBadge stage={report.timelineStage} /> : '-'}</td>
                    <td>{formatDuration(report.timelineStageSpentTime)}</td>
                  </>
                )}
                {isTimeline && isStatusTimeline && (
                  <>
                    <td>{report.timelineStatus ? statusesMap[report.timelineStatus] : '-'}</td>
                    <td>{formatDuration(report.timelineStatusSpentTime)}</td>
                  </>
                )}
                <td>{report.project.address?.[0] || '-'}</td>
                {settings.module !== AnalyticsModule.CLIENTS && (
                  <td>
                    <ProjectChipContainer>
                      {report.project.parentProject && (
                        <ProjectTitleChip target="_blank" project={report.project.parentProject} />
                      )}
                    </ProjectChipContainer>
                  </td>
                )}
                <td>{formatDate(report.project.createdAt, 'MM/DD/YYYY hh:mm A')}</td>

                {columns.map((column) => {
                  if ('isComputed' in column) {
                    return <td key={column.name}>{column.formatValue(report)}</td>;
                  }

                  return (
                    <PropertyValue
                      key={column.id}
                      MainWrapper={TemplateTableCell}
                      ValueWrapper={ValueWrapper}
                      project={report.adaptedProject}
                      property={column}
                      withTooltip
                      withCopy
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot ref={loadingRef}>
          <tr>
            {(isFetching || isFetchingNextPage) && (
              <td colSpan={totalColumns}>
                <NextPageLoaderWrapper>
                  <Loader size={48} />
                </NextPageLoaderWrapper>
              </td>
            )}
          </tr>
        </tfoot>
      </Table>
    </TableContainer>
  );
};
