import React, { useCallback, useMemo, useState } from 'react';

import { PrivilegedTask, TaskStatus, TaskStatusEntity } from '@generated/types/graphql';
import { Popover } from '@common/Popover';
import { ArrowRight, CheckCircle, ChevronDown, ChevronUp, ChevronsRight, Play, StopCircle, Truck } from 'react-feather';
import { TASK_STATUS_COLORS } from '@styles';
import {
  Container,
  SplitButton,
  SplitArrow,
  StatusText,
  Menu,
  TransitionListItem,
  TransitionGroupName,
  TransitionGroup,
  TransitionList,
  ShortcutButton,
  WorkOrderStatus
} from './styled';
import { useInlineUpdateField } from '../Fields/useInlineUpdateField';

interface Props {
  workOrder: PrivilegedTask;
  onUpdated?: () => void;
  isReadOnly?: boolean;
}

enum TaskGroupName {
  Allocated = 'Allocated',
  InProgress = 'In progress',
  Closed = 'Closed'
}

const TASK_GROUPS: { [key in TaskGroupName]: TaskStatus[] } = {
  [TaskGroupName.Allocated]: [TaskStatus.Queued, TaskStatus.Assigned, TaskStatus.Scheduled, TaskStatus.Dispatched],
  [TaskGroupName.InProgress]: [
    TaskStatus.OnTheWay,
    TaskStatus.Working,
    TaskStatus.ClockedOut,
    TaskStatus.Review,
    TaskStatus.ChangesRequested,
    TaskStatus.Working,
    TaskStatus.Waiting,
    TaskStatus.ClockedIn
  ],
  [TaskGroupName.Closed]: [TaskStatus.Completed, TaskStatus.Cancelled]
};

type ShortcutButtonConfig = {
  title: string;
  icon: React.ReactNode;
  toStatus: TaskStatus;
  color: string;
  from?: TaskStatus[];
};
const ShortcutButtonConfigs: ShortcutButtonConfig[] = [
  {
    toStatus: TaskStatus.OnTheWay,
    title: 'On the way',
    icon: <Truck size="16px" color={TASK_STATUS_COLORS[TaskStatus.OnTheWay].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.OnTheWay].color,
    from: [TaskStatus.Dispatched]
  },
  {
    toStatus: TaskStatus.Working,
    title: 'Start working',
    icon: <Play size="16px" color={TASK_STATUS_COLORS[TaskStatus.Working].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.Working].color,
    from: [TaskStatus.Assigned, TaskStatus.Scheduled, TaskStatus.OnTheWay]
  },
  {
    toStatus: TaskStatus.ClockedIn,
    title: 'Clock In',
    icon: <Play size="16px" color={TASK_STATUS_COLORS[TaskStatus.ClockedIn].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.ClockedIn].color,
    from: [TaskStatus.Assigned, TaskStatus.Scheduled, TaskStatus.OnTheWay, TaskStatus.Dispatched]
  },
  {
    toStatus: TaskStatus.ClockedOut,
    title: 'Clock Out',
    icon: <StopCircle size="16px" color={TASK_STATUS_COLORS[TaskStatus.ClockedOut].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.ClockedOut].color,
    from: [TaskStatus.ClockedIn]
  },
  {
    toStatus: TaskStatus.Waiting,
    title: 'Move to Waiting',
    icon: <StopCircle size="16px" color={TASK_STATUS_COLORS[TaskStatus.Waiting].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.Waiting].color,
    from: [TaskStatus.Working]
  },
  {
    toStatus: TaskStatus.Review,
    title: 'Send to review',
    icon: <ChevronsRight size="16px" color={TASK_STATUS_COLORS[TaskStatus.Review].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.Review].color
  },
  {
    toStatus: TaskStatus.Completed,
    title: 'Mark completed',
    icon: <CheckCircle size="16px" color={TASK_STATUS_COLORS[TaskStatus.Completed].color} />,
    color: TASK_STATUS_COLORS[TaskStatus.Completed].color
  }
];

export const StatusButton = ({ workOrder, onUpdated, isReadOnly = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const statusColor = TASK_STATUS_COLORS[workOrder.taskStatus.id] || { color: '#000', background: '#fff' };
  const { isLoading, onSubmit } = useInlineUpdateField(workOrder, onUpdated);

  const transitions = useMemo(() => {
    const groupedTransitions: { [key in TaskGroupName]: TaskStatusEntity[] } = {
      [TaskGroupName.Allocated]: [],
      [TaskGroupName.InProgress]: [],
      [TaskGroupName.Closed]: []
    };

    workOrder.taskStatus.possibleTransitions.forEach((status) => {
      const group = Object.keys(TASK_GROUPS).find((groupName) =>
        TASK_GROUPS[groupName as TaskGroupName].includes(status.id)
      ) as TaskGroupName;

      if (group) {
        groupedTransitions[group].push(status);
      }
    });

    return groupedTransitions;
  }, [workOrder.taskStatus]);

  const shortcutButton = useMemo(
    () =>
      ShortcutButtonConfigs.find((config) =>
        workOrder.taskStatus.possibleTransitions.some(
          (status) => status.id === config.toStatus && (!config.from || config.from.includes(workOrder.taskStatus.id))
        )
      ),
    [workOrder.taskStatus]
  );

  const handleChange = (status: TaskStatus) => async () => {
    onSubmit({ status });
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  if (workOrder.isArchived) {
    return <WorkOrderStatus status={workOrder.taskStatus} isArchived />;
  }

  return (
    <Container>
      <Popover
        open={isOpen}
        onClose={handleClose}
        content={
          <Menu>
            {Object.keys(transitions).map((group) => {
              const groupName = group as TaskGroupName;

              if (!transitions[groupName].length) {
                return null;
              }

              return (
                <TransitionGroup key={groupName}>
                  <TransitionGroupName>{groupName}</TransitionGroupName>
                  <TransitionList>
                    {transitions[groupName].map((transition) => (
                      <TransitionListItem key={transition.id} onClick={handleChange(transition.id)}>
                        <WorkOrderStatus status={transition} />
                      </TransitionListItem>
                    ))}
                  </TransitionList>
                </TransitionGroup>
              );
            })}
          </Menu>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SplitButton color={statusColor.color} background={statusColor.background}>
          {!isReadOnly && (
            <SplitArrow onClick={isReadOnly || isLoading ? undefined : toggle}>
              {isOpen ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}
            </SplitArrow>
          )}
          <StatusText>{workOrder.taskStatus.label}</StatusText>
        </SplitButton>
      </Popover>
      {!isReadOnly && shortcutButton && <ArrowRight size="16px" color="#9C9CAA" />}
      {!isReadOnly && shortcutButton && (
        <ShortcutButton
          disabled={isLoading}
          onClick={handleChange(shortcutButton.toStatus)}
          color={shortcutButton.color}
          startIcon={shortcutButton.icon}
          variant="text"
        >
          {shortcutButton.title}
        </ShortcutButton>
      )}
    </Container>
  );
};
