import { FilterOperators, Property } from '@types';
import { isAddress, isAhj, isDate, isDropdown, isFiles, isNumeric, isPerson, isStage, isText } from '@utils/properties';
import { InternalConfigutationError } from '@features/Analytics/InternalConfigurationError';
import { DeepPartial } from 'redux';
import {
  DatetimeFilter,
  IntFilter,
  ProjectReportFilter,
  StringFilter,
  StringListFilter
} from '@generated/types/graphql';
import { DateTime } from 'luxon';
import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import { AnalyticsWidgetDateRangeType } from '@features/Analytics/types';
import { FilterHandlerFn } from './types';

const getPropertyName = (property: Property) => {
  let propertyName = property.mappedName as keyof ProjectReportFilter;

  if ((propertyName as string) === 'createdById') {
    propertyName = 'createdBy';
  }

  return propertyName;
};

const buildLikeFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isRegularText]
  if (!isText(property) || isAddress(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (isAhj(property)) {
    if (!filter.value) {
      return {
        jurisdictionExists: false
      };
    }

    return {
      jurisdiction: {
        name: {
          includesInsensitive: filter.value as string
        }
      }
    };
  }

  if (!filter.value) {
    const stringFilter: DeepPartial<StringFilter> = {
      equalTo: ''
    };
    const nullFilter: DeepPartial<StringFilter> = {
      isNull: true
    };

    return {
      or: [
        {
          [propertyName]: stringFilter
        },
        {
          [propertyName]: nullFilter
        }
      ]
    };
  }

  const stringFilter: DeepPartial<StringFilter> = {
    includesInsensitive: filter.value as string
  };

  return {
    [propertyName]: stringFilter
  };
};

const buildNotEqualToFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isRegularText, PropertyType.Date, PropertyType.DateTime, PropertyType.Numeric]
  if ((!isText(property) && !isDate(property) && !isNumeric(property)) || isAddress(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (isAhj(property)) {
    if (!filter.value) {
      return {
        jurisdictionExists: true
      };
    }

    return {
      jurisdiction: {
        name: {
          notIncludesInsensitive: filter.value as string
        }
      }
    };
  }

  if (!filter.value) {
    const emptyFilter: DeepPartial<StringFilter | DatetimeFilter> = {
      equalTo: ''
    };
    const nullFilter: DeepPartial<StringFilter | DatetimeFilter | IntFilter> = {
      isNull: true
    };

    if (isNumeric(property) || isDate(property)) {
      return {
        not: {
          [propertyName]: nullFilter
        }
      };
    }

    return {
      not: {
        or: [
          {
            [propertyName]: emptyFilter
          },
          {
            [propertyName]: nullFilter
          }
        ]
      }
    };
  }

  if (isText(property)) {
    const stringFilter: DeepPartial<StringFilter> = {
      notIncludesInsensitive: filter.value as string
    };

    return {
      or: [
        {
          [propertyName]: stringFilter
        },
        {
          [propertyName]: {
            isNull: true
          }
        }
      ]
    };
  }

  if (isDate(property)) {
    const dateTime = DateTime.fromISO(filter.value as string);

    const datetimeFilter: DeepPartial<DatetimeFilter> = {
      greaterThanOrEqualTo: dateTime.startOf('day').toISO(),
      lessThanOrEqualTo: dateTime.endOf('day').toISO()
    };

    return {
      or: [
        {
          not: {
            [propertyName]: datetimeFilter
          }
        },
        {
          [propertyName]: {
            isNull: true
          }
        }
      ]
    };
  }

  const intFilter: DeepPartial<IntFilter> = {
    notEqualTo: parseFloat(filter.value) as number
  };

  return {
    or: [
      {
        [propertyName]: intFilter
      },
      {
        [propertyName]: {
          isNull: true
        }
      }
    ]
  };
};

const buildEqualToFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Date, PropertyType.DateTime, PropertyType.Numeric, isWorkOrderTypeField]
  if (!isDate(property) && !isNumeric(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    const nullFilter: DeepPartial<DatetimeFilter | IntFilter> = {
      isNull: true
    };

    return {
      [propertyName]: nullFilter
    };
  }

  if (isDate(property)) {
    const dateTime = DateTime.fromISO(filter.value as string);

    const datetimeFilter: DeepPartial<DatetimeFilter> = {
      greaterThanOrEqualTo: dateTime.startOf('day').toISO(),
      lessThanOrEqualTo: dateTime.endOf('day').toISO()
    };

    return {
      [propertyName]: datetimeFilter
    };
  }

  const intFilter: DeepPartial<IntFilter> = {
    equalTo: parseFloat(filter.value) as number
  };

  return {
    [propertyName]: intFilter
  };
};

const buildContainsLikeFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isAddressProperty]
  if (!isAddress(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {
      project: {
        or: [
          {
            projectAddressesByProjectIdExist: false
          },
          {
            projectAddressesByProjectId: {
              some: {
                address: {
                  equalTo: ''
                }
              }
            }
          }
        ]
      }
    };
  }

  return {
    project: {
      projectAddressesByProjectId: {
        some: {
          address: {
            includesInsensitive: filter.value as string
          }
        }
      }
    }
  };
};

const buildInFiler: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);
  // scope: [PropertyType.Person, isStageProperty, isRegularSingleDropdown, isWorkflowField, isWorkOrderTemplateField]
  if (!isPerson(property) && !isStage(property) && !(isDropdown(property) && !property.multiple)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    return {
      [propertyName]: {
        isNull: true
      }
    };
  }

  if (isPerson(property) || isStage(property)) {
    const intFilter: DeepPartial<IntFilter> = {
      in: filter.value as number[]
    };

    return {
      [propertyName]: intFilter
    };
  }

  return {
    [propertyName]: {
      in: filter.value as string[]
    }
  };
};

const buildNotInFiler: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Person, isStageProperty, isRegularSingleDropdown, isWorkflowField, isWorkOrderTemplateField]
  if (!isPerson(property) && !isStage(property) && !(isDropdown(property) && !property.multiple)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    return {
      [propertyName]: {
        isNull: false
      }
    };
  }

  if (isPerson(property) || isStage(property)) {
    const intFilter: DeepPartial<IntFilter> = {
      notIn: filter.value as number[]
    };

    return {
      [propertyName]: intFilter
    };
  }

  return {
    [propertyName]: {
      notIn: filter.value as string[]
    }
  };
};

const buildContainsFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !Array.isArray(filter.value) || !filter.value.length) {
    const notNullFilter: DeepPartial<StringListFilter> = {
      isNull: false
    };

    return {
      [propertyName]: notNullFilter
    };
  }

  const stringListFilter: DeepPartial<StringListFilter> = {
    overlaps: filter.value as string[]
  };

  return {
    [propertyName]: stringListFilter
  };
};

const buildNotContainsFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !Array.isArray(filter.value) || !filter.value.length) {
    const nullFilter: DeepPartial<StringListFilter> = {
      isNull: true
    };

    return {
      [propertyName]: nullFilter
    };
  }

  const stringListFilter: DeepPartial<StringListFilter> = {
    overlaps: filter.value as string[]
  };

  return {
    not: {
      [propertyName]: stringListFilter
    }
  };
};

const buildContainedByFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !Array.isArray(filter.value) || !filter.value.length) {
    const nullFilter: DeepPartial<StringListFilter> = {
      isNull: true
    };

    return {
      [propertyName]: nullFilter
    };
  }

  const stringListFilter: DeepPartial<StringListFilter> = {
    contains: filter.value as string[]
  };

  return {
    [propertyName]: stringListFilter
  };
};

const buildNotContainedByFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !Array.isArray(filter.value) || !filter.value.length) {
    const nullFilter: DeepPartial<StringListFilter> = {
      isNull: false
    };

    return {
      [propertyName]: nullFilter
    };
  }

  const stringListFilter: DeepPartial<StringListFilter> = {
    contains: filter.value as string[]
  };

  return {
    not: {
      [propertyName]: stringListFilter
    }
  };
};

const buildLessThanFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Numeric]
  // scope: [PropertyType.Date] // this is only for old smart views which were not properly migrated
  if (!isNumeric(property) && !isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  if (isDate(property)) {
    return buildBeforeFilter({ property, filter });
  }

  const intFilter: DeepPartial<IntFilter> = {
    lessThan: parseFloat(filter.value) as number
  };

  return {
    [propertyName]: intFilter
  };
};

const buildGreaterThanFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Numeric]
  // scope: [PropertyType.Date] // this is only for old smart views which were not properly migrated
  if (!isNumeric(property) && !isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  if (isDate(property)) {
    return buildAfterFilter({ property, filter });
  }

  const intFilter: DeepPartial<IntFilter> = {
    greaterThan: parseFloat(filter.value) as number
  };

  return {
    [propertyName]: intFilter
  };
};

const buildBeforeFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Date], PropertyType.DateTime,
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    lessThan: dateTime.startOf('day').toISO()
  };

  return {
    [propertyName]: datetimeFilter
  };
};

const buildAfterFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Date], PropertyType.DateTime,
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThan: dateTime.endOf('day').toISO()
  };

  return {
    [propertyName]: datetimeFilter
  };
};

const buildWithinFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Date], PropertyType.DateTime,
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[filter.value as AnalyticsWidgetDateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Unsupported date range type ${filter.value}`);
  }

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateRangeOption.startDate().toISO(),
    lessThanOrEqualTo: dateRangeOption.endDate().toISO()
  };

  return {
    [propertyName]: datetimeFilter
  };
};

const buildNotWithinFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.Date], PropertyType.DateTime,
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[filter.value as AnalyticsWidgetDateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Unsupported date range type ${filter.value}`);
  }

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateRangeOption.startDate().toISO(),
    lessThanOrEqualTo: dateRangeOption.endDate().toISO()
  };

  return {
    not: {
      [propertyName]: datetimeFilter
    }
  };
};

const buildIsEmptyFilter = ({ property }: { property: Property }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.File]
  if (!isFiles(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  return {
    [propertyName]: {
      isNull: true
    }
  };
};

const buildIsNotEmptyFilter = ({ property }: { property: Property }) => {
  const propertyName = getPropertyName(property);

  // scope: [PropertyType.File]
  if (!isFiles(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  return {
    [propertyName]: {
      isNull: false
    }
  };
};

export const standardPropertiesFilterHandlers: Record<FilterOperators, FilterHandlerFn> = {
  [FilterOperators.Like]: buildLikeFilter,
  [FilterOperators.NotEqualTo]: buildNotEqualToFilter,
  [FilterOperators.EqualTo]: buildEqualToFilter,
  [FilterOperators.ContainsLike]: buildContainsLikeFilter,
  [FilterOperators.In]: buildInFiler,
  [FilterOperators.NotIn]: buildNotInFiler,
  [FilterOperators.Contains]: buildContainsFilter,
  [FilterOperators.NotContains]: buildNotContainsFilter,
  [FilterOperators.ContainedBy]: buildContainedByFilter,
  [FilterOperators.NotContainedBy]: buildNotContainedByFilter,
  [FilterOperators.LessThan]: buildLessThanFilter,
  [FilterOperators.GreaterThan]: buildGreaterThanFilter,
  [FilterOperators.Before]: buildBeforeFilter,
  [FilterOperators.After]: buildAfterFilter,
  [FilterOperators.Within]: buildWithinFilter,
  [FilterOperators.NotWithin]: buildNotWithinFilter,
  [FilterOperators.IsEmpty]: buildIsEmptyFilter,
  [FilterOperators.IsNotEmpty]: buildIsNotEmptyFilter
};
